var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.changeRequestData.insured)?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v(" Insured Name and Mailing Address ")]),_c('div',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.changeRequestData.insured.firstName))+" "+_vm._s(_vm.capitalizeFirstLetter(_vm.changeRequestData.insured.lastName))+" ")]),(_vm.changeRequestData.insured.insuredAddress)?_c('div',[_vm._v(" "+_vm._s(_vm.changeRequestData.insured.insuredAddress.houseNumber)+" "+_vm._s(_vm.changeRequestData.insured.insuredAddress.streetName)+" ")]):_vm._e(),(_vm.changeRequestData.insured.insuredAddress)?_c('div',[_vm._v(" "+_vm._s(_vm.changeRequestData.insured.insuredAddress.city)+" "+_vm._s(_vm.changeRequestData.insured.insuredAddress.state)+" "+_vm._s(_vm.changeRequestData.insured.insuredAddress.zipCode)+" ")]):_vm._e()]):_vm._e(),(
      _vm.changeRequestData.insured.location &&
        _vm.changeRequestData.insured.location.houseNumber
    )?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v(" Location Address ")]),_c('div',[_vm._v(" "+_vm._s(_vm.changeRequestData.insured.location.houseNumber)+" "+_vm._s(_vm.changeRequestData.insured.location.streetName)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.changeRequestData.insured.location.city)+" "+_vm._s(_vm.changeRequestData.insured.location.state)+" "+_vm._s(_vm.changeRequestData.insured.location.zipCode)+" ")])]):_vm._e(),(_vm.changeRequestData.mortgage)?_c('div',[(
        _vm.changeRequestData.mortgage.mortgageAddress.city &&
          _vm.changeRequestData.mortgage.mortgageAddress.state &&
          _vm.changeRequestData.mortgage.mortgageAddress.zipCode
      )?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v("Mortgage Address")]),_c('div',[_vm._v(" "+_vm._s(_vm.changeRequestData.mortgage.mortgageAddress.city)+" "+_vm._s(_vm.changeRequestData.mortgage.mortgageAddress.state)+" "+_vm._s(_vm.changeRequestData.mortgage.mortgageAddress.zipCode)+" ")])]):_vm._e()]):_vm._e(),(_vm.changeRequestData.agentInfo)?_c('div',{staticClass:"mt-4 pt-4"},[_c('div',{staticClass:"mb-2 flex justify-between"},[(
          _vm.changeRequestData.agentInfo.agencyData &&
            _vm.changeRequestData.agentInfo.agencyData.agencyName
        )?_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v("Agency Name")]),_c('div',[_vm._v(" "+_vm._s(_vm.changeRequestData.agentInfo.agencyData.agencyName)+" ")])]):_vm._e(),(
          _vm.changeRequestData.agentInfo.phone &&
            _vm.changeRequestData.agentInfo.phone.primary
        )?_c('div',{staticClass:"w-1/3"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v("Phone Number")]),_c('div',[_vm._v(_vm._s(_vm.changeRequestData.agentInfo.phone.primary))])]):_vm._e()]),_c('div',{staticClass:"mb-2 flex justify-between"},[(_vm.changeRequestData.agentInfo.displayName)?_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v("Submitted By")]),_c('div',[_vm._v(_vm._s(_vm.changeRequestData.agentInfo.displayName))])]):_vm._e(),(_vm.changeRequestData.policyInfo.agencyCode)?_c('div',{staticClass:"w-1/3"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v("Agency Code")]),_c('div',[_vm._v(_vm._s(_vm.doFormatAgencyCode()))])]):_vm._e()]),_c('div',{staticClass:"mb-2 flex justify-between"},[(_vm.changeRequestData.agentInfo.email)?_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v("Email Address")]),_c('div',[_vm._v(_vm._s(_vm.changeRequestData.agentInfo.email))])]):_vm._e(),(_vm.changeRequestData.policyInfo.agencyName)?_c('div',{staticClass:"w-1/3"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v("Agency Name")]),_c('div',[_vm._v(_vm._s(_vm.changeRequestData.policyInfo.agencyName))])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }